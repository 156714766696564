export const data1 = [
  { x: '5:31pm', y: 80 },
  { x: '5:32pm', y: 110 },
  { x: '5:33pm', y: 60 },
  { x: '5:34pm', y: 150 },
  { x: '5:35pm', y: 220 },
  { x: '5:36pm', y: 80 },
  { x: '5:37pm', y: 110 },
  { x: '5:38pm', y: 60 },
  { x: '5:39pm', y: 150 },
  { x: '5:40pm', y: 220 },
]

export const data2 = [
  { x: '5:31pm', y: 34 },
  { x: '5:32pm', y: 70 },
  { x: '5:33pm', y: 10 },
  { x: '5:34pm', y: 90 },
  { x: '5:35pm', y: 110 },
  { x: '5:36pm', y: 100 },
  { x: '5:37pm', y: 90 },
  { x: '5:38pm', y: 120 },
  { x: '5:39pm', y: 70 },
  { x: '5:40pm', y: 20 },
]

export const data3 = [
  { x: '5:31pm', y: 50 },
  { x: '5:32pm', y: 20 },
  { x: '5:33pm', y: 30 },
  { x: '5:34pm', y: 10 },
  { x: '5:35pm', y: 40 },
  { x: '5:36pm', y: 10 },
  { x: '5:37pm', y: 10 },
  { x: '5:38pm', y: 10 },
  { x: '5:39pm', y: 20 },
  { x: '5:40pm', y: 30 },
]
